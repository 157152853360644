module.exports = {
  button: {
    home: "数据看板",
    Equipment: "厂房设施",
    waste: "能源管理",
    Operation: "运维任务",
    Equipmentall: "生产设备",
  },
  //新页面设备
  HomeEquipment:{
    news:{
      model:"型号",
      name:"设备",
      number:"编号"
    },
    information: {
      Device: "今日信息",
      temperature: "温度",
      humidness: "湿度",
      weather: "天气",
    },
    Energy: {
      Totalnew:'工厂设施总数',
      total: "设备数据",
      health: "健康",
      focus: "关注",
      run: "运行",
      slight:"轻微",
      seriousness:"严重",
      stop:"停机",
      Healthproportion:"健康占比",
      Operatingrate:"开机率",
      Runnumber:"运行数",
      Stopnumber:"停机数",
      Unknownumber:"不明数",
    },
    Machine:{
      name: "设备信息",
      time:"更新时间",
      model:"型号",
      Serialnumber:"序列号",
      power:"电机功率",
      number:"编号",
      speed:"设备转速",
      hours:"工作时长",
      velocity:"振动速度均方根",
      peak:"峰值因素",
      surface:"表面温度",
      componenthealth:"部件健康度",
      classpulley:"一级皮带轮",
      electricmachine:"电机",
      motordriver:"电机驱动器",
      location:"位置",
      speedvelocity:"速度均方根",
      envelope:"包络能量",
      kurtosis:"峭度",
      detection:"设备智能检测",
      remaining:"预计剩余时间",
      jobname:"作业名称",
      jobnum:"作业数量",
      runtime:'运行时间',
      conerror:'无法连接',
      produce:"生产",
      warning:"警告",
      error:'错误',
      reference:'阈值参考'
    }
  },
  // 首页
  HomeScreen: {
    Profacilityinfor:'生产设备',
    information: {
      Device: "设备实施信息",
      Total: "工厂设施总数",
      tower: "台",
      Active: "在用工厂设施",
      TotalSpare: "生产设备总数",
      Spent: "运行生产设备",
      individual: "个",
    },
    task: {
      Operation: "运维任务",
      Total: "任务总数",
      Monthly: "本月任务",
      Currentday: "今日任务",
      Repair: "维修",
      Maintenance: "维保",
      Inspection: "巡检",
      Others: "其他",
      individual: "个",
      TaskStatistics: "任务统计",
      completed: "已完成",
      uncompleted: "未完成",
    },
    Energy: {
      management: "能源管理",
      Currentday: "今日发电量",
      Monthlytotal: "本月发电量",
      Recenttotal: "近期发电量",
      day: "日",
    },
    use: {
      Energy: "能源使用情况",
      Totalgenerated: "发电总量",
      COreduction: "减排总量",
      Yearlyview: "本年度发电量",
      T: "吨",
      January: "1月",
      February:'2月',
      March: "3月",
      April: "4月",
      May: "5月",
      June: "6月",
      July: "7月",
      August: "8月",
      September: "9月",
      October: "10月",
      November: "11月",
      December: "12月",
    },
    Energystorageinformation:"储能信息"
  },
  //   设备
  DeviceInformation: {
    Today: {
      Todayinformation: "今日信息",
      Weather: "温度",
      Date: "日期",
      Task: "任务统计",
      Uncompleted: "待执行",
      Completed: "已执行",
    },
    Device: {
      Devicestatistics: "设备统计",
      Deviceusage: "设备使用情况",
      Usagerate: "使用率",
      Deviceinuse: "使用中数量",
      Deactivateddevice: "已停用数量",
      tower: "台",
      piece: "件",
      Inspectionplan: "巡检/维保计划",
      Cycle: "周期",
    },
    alert: {
      Realtimealert: "实时警报",
      Time: "报修时间",
      Type: "报修类型",
      Device: "报修设备",
      currentyear: "当前年度故障次数排名",
      Times: "次数",
    },
    information: {
      Deviceinformation: "设备信息",
      Operation: "运行情况",
      Name: "设备名称",
      Type: "设备类型",
      ID: "设备编号",
      inspection: "巡检情况-本月",
      maintenance: "维保情况-本月",
      All: "全部",
      Uncompleted: "待完成",
      Completed: "已完成",
    },
  },
  //   废危
  HazardousWaste: {
    progress:'页面升级中，敬请期待',
    Setvalue: "设定值",
    Currentvalue: "当前值",
    time: "连续蒸发时间",
    Runningtime: "运行时间",
    dischargetime: "浓缩排放时间",
    temperaturesetvalue: "冷凝水温度设定值",
    temperature: "冷凝水温度",
    alarm: "排气压力报警",
    settingvalue: "冷媒压力设定值",
    Refrigerantpressure: "冷媒压力",
    Wastefeedtime: "废液进料时间",
    Exhaustpressuresetvalue: "排气压力设定值",
    Exhaustpressure: "排气压力",
    Cleaningtime: "清洗时间",
    Evaporationsetvalue: "蒸发罐压力设定值",
    Evaporationtankpressure: "蒸发罐压力",
    Concentrationtime: "浓缩时间",
    EnergyInformation:"储能信息",
    batteryStatus:"当前电池状态",
    Charging:"正在充电",
    discharge:"正在放电",
    idle:"空闲",
    Shutdown:"关机",
    electricData:"电力数据",
    chargingpower:"累计充电总电量",
    dischargepower:"累计放电总电量",
    electricitysavings:"累计节约电费",
    yuan:"元",
    datastatistics:"近期数据统计",
    Chargingcapacity:"充电量",
    Dischargecapacity:"放电量",
    dischargingsituation:"充放电情况",
    price:'时段电价',
    saving:'节约电费',
    EnergyStorageCabinet:"储能柜"
  },
  //   运维
  Operationsystem: {
    analysis: {
      Platformanalysis: "平台情况分析",
      Activedevice: "在用设备",
      Disableddevice: "停用设备",
      Maintenance: "维修设备",
      Devicenumber: "设备总数",
      Monthlymaintenance: "本月维保情况",
      Totaltasks: "总任务",
      individual: "个",
      Rateofcompletion: "完成率",
      Timelinessratio: "及时率",
      Completed: "完成数量",
      Delayedcompletion: "延期完成",
      tower: "台",
      WorkOrderType:'工单类型',
      WorkOrdernNumber:'工单编号',
      WorkOrderStatus:'工单状态',
      TaskDate:'任务日期'
    },
    table: {
      Tasktypestatistics: "任务类型统计",
      Planstatistics: "计划情况统计",
      Repair: "维修",
      Maintenance: "维保",
      Inspection: "巡检",
    },
    Taskanalysis: {
      Taskanalysis: "任务整体情况分析",
      Repair: "维修",
      Maintenance: "维保",
      Inspection: "巡检",
      RepairE: "维修",
      MaintenanceE: "维保",
      InspectionE: "巡检",
      Orderprocessing: "工单处理流程",
      Neworder: "新建工单",
      Examineorder: "审核工单",
      Completeorder: "完成工单",
      Totaltoday: "今日工单总量",
      area: "各厂区情况",
      Name: "厂区名",
      device: "设备数",
      type: "设备类型数",
    },
  },
};
