module.exports = {
  button: {
    home: "Dash Board",
    Equipment: "Factory Facilities",
    waste: "Energy Management",
    Operation: "Operation and Maintenance Tasks",
    Equipmentall: "Production Equipment",
  },
  //新页面设备
  HomeEquipment: {
    news:{
      model:"Model",
      name:"Name",
      number:"MFG.No."
    },
    information: {
      Device: "Today's information",
      temperature: "Temperature",
      humidness: "Humidness",
      weather: "Weather",
    },
    Energy: {
      Totalnew:'Total production equipment',
      total: "Device Data",
      health: "Health",
      focus: "Follow",
      run: "Operation",
      slight: "Slight",
      seriousness: "Serious",
      stop: "Outage",
      Healthproportion: "Health rate",
      Operatingrate: "Operation rate",
      Runnumber: "Operation",
      Stopnumber: "Outage",
      Unknownumber: "Unknown",
    },
    Machine: {
      name: "Device information",
      time: "Update",
      model: "Model",
      Serialnumber: "Factory number",
      power: "Motor power",
      number: "Serial number",
      speed: "Speed",
      hours: "Running time",
      velocity: "RMS velocity",
      peak: "PK",
      surface: "Temperature",
      componenthealth: "Component health",
      classpulley: "一级皮带轮",
      electricmachine: "电机",
      motordriver: "Motor driver",
      location: "Location",
      speedvelocity: "RMS",
      envelope: "Envelope energy",
      kurtosis: "Kurosis",
      detection: "Equipment intelligent monitoring",
      remaining: "Estimated remaining time",
      jobname: "Name",
      jobnum: "Number",
      runtime: "Running time",
      conerror: "Connection error",
      produce: "Produce",
      warning: "Warning",
      error: "Error",
      reference:"Reference"
    },
  },
  // 首页
  HomeScreen: {
    Profacilityinfor:'Production equipment',
    information: {
      Device: "Facilities",
      Total: "Total factory devices",
      tower: "",
      Active: "In use",
      TotalSpare: "Total production equipment",
      Spent: "In use",
      individual: "",
    },
    task: {
      Operation: "Operation and maintenance tasks",
      Total: "Total",
      Monthly: "Monthly total",
      Currentday: "Current day",
      Repair: "Repair",
      Maintenance: "Maintenance",
      Inspection: "Inspection",
      Others: "Others",
      individual: "",
      TaskStatistics: "Task statistics",
      completed: "completed",
      uncompleted: "uncompleted",
    },
    Energy: {
      management: "Energy management",
      Currentday: "Current day",
      Monthlytotal: "Monthly total",
      Recenttotal: "Recent total",
      day: "",
    },
    use: {
      Energy: "Energy use",
      Totalgenerated: "Total power generation",
      COreduction: "Total emission reduction",
      Yearlyview: "Yearly overview",
      T: "T",
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
    },
    Energystorageinformation:"Energy storage information"
  },
  //   设备
  DeviceInformation: {
    Today: {
      Todayinformation: "Today's information",
      Weather: "Weather",
      Date: "Date",
      Task: "Task statistics",
      Uncompleted: "Uncompleted",
      Completed: "Completed",
    },
    Device: {
      Devicestatistics: "Device statistics",
      Deviceusage: "Device usage",
      Usagerate: "Usage rate",
      Deviceinuse: "Device in use",
      Deactivateddevice: "Deactivated device",
      tower: "",
      piece: "",
      Inspectionplan: "Inspection/Maintenance plan",
      Cycle: "Cycle",
    },
    alert: {
      Realtimealert: "Real-time alert",
      Time: "Time",
      Type: "Type",
      Device: "Device",
      currentyear: "Number of failures in the current year",
      Times: "Times",
    },
    information: {
      Deviceinformation: "Device information",
      Operation: "Operation",
      Name: "Name",
      Type: "Type",
      ID: "ID",
      inspection: "Monthly inspection statistics",
      maintenance: "Monthly maintenance statistics",
      All: "All",
      Uncompleted: "Uncompleted",
      Completed: "Completed",
    },
  },
  //   废危
  HazardousWaste: {
    progress:'Page upgrade in progress, please stay tuned',
    Setvalue: "Set value",
    Currentvalue: "Current value",
    time: "Continuous evaporation time",
    Runningtime: "Running time",
    dischargetime: "Concentration discharge time",
    temperaturesetvalue: "Condensate temperature set value",
    temperature: "Condensate temperature",
    alarm: "Exhaust pressure alarm",
    settingvalue: "Refrigerant pressure setting value",
    Refrigerantpressure: "Refrigerant pressure",
    Wastefeedtime: "Waste feed time",
    Exhaustpressuresetvalue: "Exhaust pressure set value",
    Exhaustpressure: "Exhaust pressure",
    Cleaningtime: "Cleaning time",
    Evaporationsetvalue: "Evaporation tank pressure set value",
    Evaporationtankpressure: "Evaporation tank pressure",
    Concentrationtime: "Concentration time",
    EnergyInformation:"Energy storage information",
    batteryStatus:"Current battery status",
    Charging:"Charging",
    discharge:'Discharging',
    idle:"Idle",
    Shutdown:"Shutdown",
    electricData:"electric data",
    yuan:"yuan",
    chargingpower:"Total charging power",
    dischargepower:"Total discharge power",
    electricitysavings:"Electricity savings",
    datastatistics:"Recent data statistics",
    Chargingcapacity:"Charging capacity",
    Dischargecapacity:"Discharge capacity",
    dischargingsituation:"Charging and discharging situation",
    price:'Time period electricity price',
    saving:'Energy saving',
    EnergyStorageCabinet:"Cabinet"
  },
  //   运维
  Operationsystem: {
    analysis: {
      Platformanalysis: "Device status",
      Activedevice: "Active",
      Disableddevice: "Inactive",
      Maintenance: "Under maintenance",
      Devicenumber: "Device number",
      Monthlymaintenance: "Monthly maintenance status",
      Totaltasks: "Total tasks",
      individual: "",
      Rateofcompletion: "Completion Rate",
      Timelinessratio: "Timely Rate",
      Completed: "Completed",
      Delayedcompletion: "Delayed completion",
      tower: "",
      WorkOrderType: "Work Order Type",
      WorkOrdernNumber: "Work Order Number",
      WorkOrderStatus: "Work Order Status",
      TaskDate: "Task Date",
    },
    table: {
      Tasktypestatistics: "Statistics",
      Planstatistics: "Statistics",
      Repair: "Repair",
      Maintenance: "Maintenance",
      Inspection: "Inspection",
    },
    Taskanalysis: {
      Taskanalysis: "Task analysis",
      Repair: "REP",
      Maintenance: "MAI",
      Inspection: "INS",
      RepairE: "Repair",
      MaintenanceE: "Maintenance",
      InspectionE: "Inspection",
      Orderprocessing: "Task processing",
      Neworder: "New order",
      Examineorder: "Examine order",
      Completeorder: "Complete order",
      Totaltoday: "Today's tasks",
      area: "Situation of factory area",
      Name: "Name",
      device: "Number of device",
      type: "Number of device type",
    },
  },
};
